import '../styles/digix-ui.css';
import { ContainerDeMensagensFlutuantes } from '@digix-ui/componentes';

import { routesConfig } from 'config';
import { RouterCreator } from 'router';

export function AppContext(): JSX.Element {
  return (
    <>
      <ContainerDeMensagensFlutuantes />
      <RouterCreator
        routesConfig={routesConfig}
        redirectPath="/"
        fallbackRedirectPath="/"
      />
    </>
  );
}
