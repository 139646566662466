/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { BotaoComTexto } from '@digix-ui/componentes';

import logoEProcon from 'assets/images/e-procon-logo-branco.svg';
import { useAuth } from 'hooks';

import { TipoCadastroEnum } from 'enums';
import classes from './styles.module.scss';
import useBloc from './bloc';

export function AppLayout(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const { deslogar, nome, tipoCadastro } = useAuth();
  const { handleTermosDeUso } = useBloc();
  const estaNaRotaDeFaleConosco =
    location.pathname.split('/')[1] === 'fale-conosco' ||
    location.pathname.split('/')[1] === 'canal-de-denuncias';

  return (
    <>
      <header className={clsx('cabecalho-sistema', classes.corTerciaria)}>
        <div className="cabecalho-sistema__container-primario">
          <BotaoComTexto
            visual="sem-preenchimento"
            tipo="button"
            onClick={() => navigate(0)}
            classesAuxiliares={classes.botaoLogo}
          >
            <img
              className={clsx('cabecalho-sistema__logo', classes.icone)}
              width="33"
              src={logoEProcon}
              alt="Logotipo do Atendimento"
            />
          </BotaoComTexto>
        </div>
        <div className="cabecalho-sistema__container-secundario">
          <nav className="cabecalho-sistema__menu" aria-label="Menu secundário">
            <ul className="cabecalho-sistema__lista-de-itens">
              {nome && !estaNaRotaDeFaleConosco && (
                <li className="dropdown dropdown_direita">
                  <button
                    type="button"
                    className="cabecalho-sistema__item-de-menu"
                    aria-label="Opções para o usuário Administrador"
                    aria-haspopup="true"
                  >
                    <span className="cabecalho-sistema__icone-do-usuario">
                      <i className="far fa-user" />
                    </span>
                    <span
                      className={`cabecalho-sistema__nome-do-usuario ${classes.nomeDoUsuario}`}
                    >
                      {nome}
                    </span>
                    <i className="far fa-angle-down u-margem-esquerda-pequena" />
                  </button>
                  <ul
                    className={clsx('dropdown__lista', classes.submenu)}
                    aria-label="Submenu"
                  >
                    {tipoCadastro === TipoCadastroEnum.USUARIO_CONSUMIDOR && (
                      <li className="dropdown__item">
                        <BotaoComTexto
                          classesAuxiliares="dropdown__link"
                          visual="sem-preenchimento"
                          onClick={() => navigate('/reclamacoes/meus-dados')}
                          cor="cinza"
                        >
                          <i className="fa-regular fa-pen-to-square dropdown__icone" />
                          Meus dados
                        </BotaoComTexto>
                      </li>
                    )}
                    <li
                      className="dropdown__item"
                      style={{ cursor: 'pointer' }}
                    >
                      <BotaoComTexto
                        classesAuxiliares="dropdown__link"
                        visual="sem-preenchimento"
                        cor="cinza"
                        onClick={() => {
                          if (
                            tipoCadastro ===
                              TipoCadastroEnum.USUARIO_CONSUMIDOR ||
                            tipoCadastro === TipoCadastroEnum.USUARIO_FORNECEDOR
                          ) {
                            handleTermosDeUso(tipoCadastro);
                          }
                        }}
                      >
                        <i className="fa-regular fa-lock dropdown__icone" />
                        Termos de uso
                      </BotaoComTexto>
                    </li>
                    <li className="dropdown__item">
                      <BotaoComTexto
                        classesAuxiliares="dropdown__link"
                        visual="sem-preenchimento"
                        onClick={deslogar}
                        cor="cinza"
                      >
                        <i className="far fa-sign-out dropdown__icone" />
                        Sair
                      </BotaoComTexto>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </header>
      <Outlet />
    </>
  );
}
